"use client";
import React, { useState } from "react";
import MyContext from "./MyContext.js";

const MyContextProvider = ({ children }) => {
    const [technologies, setTechnologies] = useState("Blockchain");
    const [technologiesChildData, setTechnologiesChildData] =
        useState("ReactJS");
    const [blogAutherName, setBlogAutherName] = useState("");
    const [modifiedHourlyData, setModifiedHourlyData] = useState([
        {
            id: 1,
            title: "For 40 Hours",
            hour: "from $40/ hr",
            days: "*Validity 30 days",
        },
        {
            id: 2,
            title: "For 80 Hours",
            hour: "from $37/ hr",
            days: "*Validity 90 days",
        },
        {
            id: 3,
            title: "For 120 Hours",
            hour: "from $37/ hr",
            days: "*Validity 120 days",
        },
    ]);
    const [modifiedMonthlyData, setModifiedMonthlyData] = useState([
        {
            id: 1,
            title: "Associate Level",
            hour: "from $1850/ mo",
            experience: "1-2 years Experienced",
        },
        {
            id: 2,
            title: "Junior Level",
            hour: "from $2250/ mo",
            experience: "2-3 years Experienced",
        },
        {
            id: 3,
            title: "Mid Level",
            hour: "from $2650/ mo",
            experience: "3-5 years Experienced",
        },
        {
            id: 4,
            title: "Senior Level",
            hour: "from $3650/ mo",
            experience: "5-7 years Experienced",
        },
        {
            id: 5,
            title: "Technical Lead",
            hour: "from $6750/ mo",
            experience: "8+ years Experienced",
        },
    ]);

    return (
        <MyContext.Provider
            value={{
                technologies,
                setTechnologies,
                technologiesChildData,
                setTechnologiesChildData,
                modifiedHourlyData,
                setModifiedHourlyData,
                modifiedMonthlyData,
                setModifiedMonthlyData,
                blogAutherName,
                setBlogAutherName,
            }}
        >
            {children}
        </MyContext.Provider>
    );
};

export default MyContextProvider;
